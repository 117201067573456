import { Action } from "api/action_types/region";
import { ActionType } from "api/action_types/region";
import { Region } from "api/types/region";
import { SubRegion } from "api/types/region";

interface State {
  regions: Region[],
  sub_regions: SubRegion[]
}

const initialState = {
  regions: [],
  sub_regions: []
}

export const regionReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_REGIONS:
      return {
        ...state,
        regions: action.regions
      }
    case ActionType.GET_SUB_REGIONS:
      return {
        ...state,
        sub_regions: action.sub_regions
      }
    default:
      return state;
  }
}



