import { useAppSelector } from "api/hooks/apiHook";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import MiniLogout from "components/authentication/mini_logout";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { create_account_url } from "components/navigation/endpoints";
import { home_url } from "components/navigation/endpoints";
import { miniListItemButton } from "styles/drawer";
import { miniDrawerIconStyle } from "styles/drawer";
import { drawerListItemStyle } from "styles/drawer";
import { drawerHighlightStyle } from "styles/drawer";
import { drawerHighlightIconStyle } from "styles/drawer";
import { miniDrawerWidth } from "styles/drawer";

export default function MiniDrawer() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAppSelector((state) => state.authentication);

  const buildPath = (): string => {
    const paths = location.pathname.split("/");
    let current_path = "";
    for (let i = 0; i < paths.length; i++) {
      if (i === 0) {
        continue;
      }
      if (i === 1) {
        current_path = paths[i];
        continue;
      }
      current_path += `/${paths[i]}`;
    }
    return current_path;
  }

  const getListItemStyle = (url: string) => {
    const current_path = buildPath();
    if (current_path === url) {
      return drawerHighlightStyle;
    }
    return null;
  }

  const getListItemIconStyle = (url: string) => {
    const current_path = buildPath();
    if (current_path === url) {
      return drawerHighlightIconStyle;
    }
    return null;
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: miniDrawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": { width: miniDrawerWidth, boxSizing: "border-box" },
      }}>
      <Toolbar />
      {
        user.id ?
          <Box sx={{ overflow: "auto" }}>
            <List>
              <ListItem
                sx={[drawerListItemStyle, getListItemStyle("")]}
                onClick={() => navigate(home_url, { replace: false })}
                key="Dashboard">
                <ListItemButton sx={miniListItemButton}>
                  <DashboardIcon sx={[miniDrawerIconStyle, getListItemIconStyle("")]} />
                </ListItemButton>
              </ListItem>
              <ListItem
                sx={[drawerListItemStyle, getListItemStyle(create_account_url)]}
                onClick={() => navigate(create_account_url, { replace: false })}
                key="CreateAccount">
                <ListItemButton sx={miniListItemButton}>
                  <Tooltip title="Create Account" placement="right">
                    <PersonAddIcon sx={[miniDrawerIconStyle, getListItemIconStyle(create_account_url)]} />
                  </Tooltip>
                </ListItemButton>
              </ListItem>
            </List>
            <ListItem
              sx={{ position: "fixed", bottom: 0, left: 0, width: miniDrawerWidth - 2 }}
              key="Logout">
              <MiniLogout />
            </ListItem>
          </Box>
          : null
      }
    </Drawer>
  );
}
