import { Region } from "api/types/region";
import { SubRegion } from "api/types/region";

export enum ActionType {
  GET_REGIONS = "GET_REGIONS",
  GET_SUB_REGIONS = "GET_SUB_REGIONS"
}

interface IGetRegions {
  type: ActionType.GET_REGIONS,
  regions: Region[]
}

interface IGetSubRegions {
  type: ActionType.GET_SUB_REGIONS,
  sub_regions: SubRegion[]
}

export type Action = IGetRegions | IGetSubRegions;
