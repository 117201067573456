import React from "react";
import { useState } from "react";
import { User } from "api/types/authentication";
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { clearErrors } from "api/actions/authentication";
import { login } from "api/actions/authentication";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TextField from "@mui/material/TextField";
import { textFieldStyle } from "styles/style";
import { dialogContentStyle } from "styles/style";
import { primary_color } from "styles/style";
import { white_color } from "styles/style";
import { validateEmail } from "functions/value";
import { JSONIsEmpty } from "functions/value";

export default function Login() {
  const dispatch = useAppDispatch();
  const { error } = useAppSelector((state) => state.authentication);
  const { user } = useAppSelector((state) => state.authentication);
  const { login_success } = useAppSelector((state) => state.authentication);
  const { auth_failed } = useAppSelector((state) => state.authentication);
  const [open, setOpen] = useState(false);
  const [pendingLogin, setPendingLogin] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emailHasError, setEmailHasError] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [passwordHasError, setPasswordHasError] = useState(false);

  const login_open = open || auth_failed;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleLogin();
    }
    else if (event.key === "Escape") {
      event.preventDefault();
      close();
    }
  }

  const close = () => {
    dispatch(clearErrors());
    setPendingLogin(false);
    setOpen(false);
    setPassword("");
    setEmail("");
    setEmailError("");
    setPasswordError("");
    setEmailHasError(false);
    setPasswordHasError(false);
  }

  const handleEmail = (e: string) => {
    setEmail(e);
    setEmailError("");
    setEmailHasError(false);
  }

  const handlePassword = (e: string) => {
    setPassword(e);
    setPasswordError("");
    setPasswordHasError(false);
  }

  const handleLogin = () => {
    let error = false;

    if (email === "" || email === null || email === undefined || validateEmail(email) === false) {
      setEmailError("Please enter a valid email");
      setEmailHasError(true);
      error = true;
    }

    if (password === "" || password === null || password === undefined) {
      setPasswordError("Please enter a password");
      setPasswordHasError(true);
      error = true;
    }

    if (error) {
      return;
    }

    const user: User = {
      email: email,
      password: password
    }

    setPendingLogin(true);
    dispatch(login(user));
  }

  if (JSONIsEmpty(user) === false && pendingLogin) {
    setPendingLogin(false);
  }

  if (login_success) {
    window.location.reload();
  }

  if (JSONIsEmpty(user) === false && !auth_failed) {
    return null;
  }

  return (
    <Dialog open={login_open} onKeyDown={handleKeyDown}>
      <DialogTitle sx={{ backgroundColor: primary_color, color: white_color }}>
        Sign In
      </DialogTitle>
      <DialogContent sx={dialogContentStyle}>
        <FormControl fullWidth>
          <FormHelperText error id="login_error">{error}</FormHelperText>
          <TextField
            sx={textFieldStyle}
            fullWidth
            size="small"
            variant="outlined"
            id="email"
            label="Email"
            type="text"
            error={emailHasError}
            onChange={(e) => handleEmail(e.target.value)}
          />
          <FormHelperText error id="emailError">{emailError}</FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            sx={textFieldStyle}
            fullWidth
            size="small"
            variant="outlined"
            id="password"
            label="Password"
            type="password"
            error={passwordHasError}
            onChange={(e) => handlePassword(e.target.value)}
          />
          <FormHelperText error id="passwordError">{passwordError}</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={close}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
