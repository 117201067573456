/// TYPES ///
import { CSSProperties } from "react";
/// SIZES ///
import { page_left_gutter } from "values/size";
import { page_right_gutter } from "values/size";
import { page_top_gutter } from "values/size";
import { page_bottom_gutter } from "values/size";

export const title_section_style: CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "5px"
}

export const title_style: CSSProperties = {
  paddingLeft: "2px"
}

export const content_container_style: CSSProperties = {
  position: "absolute",
  top: page_top_gutter,
  bottom: page_bottom_gutter,
  left: page_left_gutter,
  right: page_right_gutter,
}

export const list_container_style: CSSProperties = {
  paddingBottom: "80px"
}

export const header_style: CSSProperties = {
  backgroundColor: "var(--mui-palette-primary-main)",
  height: "11px",
  width: "100%",
  marginBottom: "5px",
  marginTop: "5px"
}
