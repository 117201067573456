export const url_prefix: string = process.env.REACT_APP_URL_PREFIX ? process.env.REACT_APP_URL_PREFIX : "http://127.0.0.1:8000/";
export const STRIPE_PUBLIC_KEY: string = process.env.REACT_APP_STRIPE_PK ? process.env.REACT_APP_STRIPE_PK : "";
export const GOOGLE_API_KEY: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? process.env.REACT_APP_GOOGLE_MAPS_API_KEY : "AIzaSyArrEb9PSZtVy6uNSTHR_RVtjpLUQGrakE";
export const SUBSCRIPTION_PRODUCT_ID: string = process.env.REACT_APP_SUBSCRIPTION_PRODUCT_ID ? process.env.REACT_APP_SUBSCRIPTION_PRODUCT_ID : "";
export const MONTHLY_PRICE_ID: string = process.env.REACT_APP_MONTHLY_PRICE_ID ? process.env.REACT_APP_MONTHLY_PRICE_ID : "";
export const YEARLY_PRICE_ID: string = process.env.REACT_APP_YEARLY_PRICE_ID ? process.env.REACT_APP_YEARLY_PRICE_ID : "";
export const QUOTING_ONLY_PRODUCT_ID: string = process.env.REACT_APP_QUOTING_ONLY_PRODUCT_ID ? process.env.REACT_APP_QUOTING_ONLY_PRODUCT_ID : "";
export const MONTHLY_QUOTING_ONLY_PRICE_ID: string = process.env.REACT_APP_MONTHLY_QUOTING_ONLY_PRICE_ID ? process.env.REACT_APP_MONTHLY_QUOTING_ONLY_PRICE_ID : "";
export const MARKET_ONLY_PRODUCT_ID: string = process.env.REACT_APP_MARKET_ONLY_PRODUCT_ID ? process.env.REACT_APP_MARKET_ONLY_PRODUCT_ID : "";
export const MONTHLY_MARKET_ONLY_PRICE_ID: string = process.env.REACT_APP_MONTHLY_MARKET_ONLY_PRICE_ID ? process.env.REACT_APP_MONTHLY_MARKET_ONLY_PRICE_ID : "";
export const SPONSORED_PRODUCT: string = "";
