import { combineReducers } from "redux";
import { authenticationReducer } from "./authentication";
import { accountReducer } from "./account";
import { billingReducer } from "./billing";
import { regionReducer } from "./region";
import { salesReducer } from "./sales";

const reducers = combineReducers({
  authentication: authenticationReducer,
  account: accountReducer,
  billing: billingReducer,
  region: regionReducer,
  sales: salesReducer
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
