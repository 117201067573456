/// MUI COMPONENTS ///
import { ClickAwayListener } from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Popper from "@mui/material/Popper";
import { well_color } from "styles/style";

interface Props {
  open: boolean,
  setOpen: (open: boolean) => void,
  anchorEl: HTMLDivElement | null,
  predictions: any[],
  choosePlace: (place_id: string, label: string, address: string, city: string, province: string) => void
}

export default function AutocompleteResults({ open, setOpen, anchorEl, predictions, choosePlace }: Props) {
  const choose = (prediction: any) => {
    let address_prediction: string = "";
    let city_prediction: string = "";
    let province_prediction: string = "";
    if (prediction.description) {

      const seg_address: string[] = prediction.description.split(",");
      if (seg_address[0]) {
        address_prediction = seg_address[0];
      }
      if (seg_address[1]) {
        city_prediction = seg_address[1];
      }
      if (seg_address[2]) {
        province_prediction = seg_address[2].trim();
      }
    }

    choosePlace(prediction.place_id, prediction.description, address_prediction, city_prediction, province_prediction);
    setOpen(false);
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Popper
        sx={{ backgroundColor: well_color, zIndex: 1500 }}
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-start"}>
        <List>
          {
            predictions ?
              predictions.map((prediction, index) => {
                return (
                  <ListItemButton key={index} onClick={() => choose(prediction)}>
                    {prediction.description}
                  </ListItemButton>
                )
              }) :
              null
          }
        </List>
      </Popper>
    </ClickAwayListener>
  )
}

