/// TYPES ///
import { Variants } from "framer-motion";
/// COLOR ///
import { very_light_grey_color } from "styles/style";
import { primary_color } from "styles/style";

export const dash_item_motion = {
  rest: {
    backgroundColor: "var(--mui-palette-background-paper)",
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    }
  },
  hover: {
    backgroundColor: very_light_grey_color,
    transition: {
      ease: "easeIn",
      duration: 1.0,
      type: "tween"
    }
  }

}

export const dash_btn_motion: Variants = {
  rest: {
    borderLeftWidth: "0px",
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    }
  },
  hover: {
    borderLeftWidth: "15px",
    color: primary_color,
    transition: {
      ease: "easeIn",
      duration: 0.2,
      type: "tween"
    }
  },
}

export const plan_inner_motion: Variants = {
  rest: {
    borderRightWidth: "0px",
    transition: {
      ease: "easeIn",
      duration: 0.1,
      type: "tween"
    }
  },
  hover: {
    backgroundColor: primary_color,
    borderRightWidth: "15px",
    transition: {
      ease: "easeIn",
      duration: 0.2,
      type: "tween"
    }
  },
}
