/// HOOKS ///
import { useEffect } from "react";
import { useState } from "react";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
/// COMPONENTS ///
import RegistrationComponent from "./tabs/account_details";
import StaffBilling from "./tabs/billing";
import StaffPaymentForm from "./tabs/payment";
import StaffChoosePlan from "./tabs/plan";
import CompleteRegistration from "./tabs/complete";
import Steppers from "components/universal/stepper";
import PageTitle from "components/universal/page/title";
/// STYLES ///
import { mainContentAreaStyle } from "styles/style";
/// VALUES ///
import { staff_payment_setup_steps } from "values/steps";

export default function CreateAccount() {
  const [step, setStep] = useState<number>(0);

  useEffect(() => {
    setStep(0);
  }, []);

  return (
    <Box sx={mainContentAreaStyle}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <PageTitle title="Create Customer" />
        </Grid>
        <Grid item xs={8.5} sx={{ marginTop: 4 }}>
          <Grid container direction="row" justifyContent="space-between">
            {
              step === 0 ?

                <StaffChoosePlan setStep={setStep} /> :
                null
            }
            {
              step === 1 ?
                <RegistrationComponent setStep={setStep} /> :
                null
            }
            {
              step === 2 ?
                <StaffBilling setStep={setStep} /> :
                null
            }
            {
              step === 3 ?
                <StaffPaymentForm /> :
                null
            }
            {
              step === 5 ?
                <CompleteRegistration setStep={setStep} /> :
                null
            }
          </Grid>
        </Grid>
        <Grid item xs={3} sx={{ marginTop: 4 }}>
          <Steppers current_step={step} steps={staff_payment_setup_steps} />
        </Grid>
      </Grid>
    </Box>
  )
}
