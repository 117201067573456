/// REACT ROUTER DOM ///
import { BrowserRouter } from "react-router-dom";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
/// COMPONENTS ///
import NavBar from "components/navigation/navbar";
import Home from "components/home/home";
import MiniDrawer from "components/drawer/mini_drawer";
import ChangePassword from "components/profile/change_password";
import ForgotPassword from "components/authentication/forgot_password";
import LoginPage from "components/authentication/login_page";
import StaffSubscriptionSetupConfirmation from "components/account/confirmation";
import StaffSubscriptionSetupCancellation from "components/account/cancellation";
import CreateAccount from "components/account/create_account";
/// ENDPOINTS ///
import { forgot_password_url } from "./endpoints";
import { home_url } from "./endpoints";
import { create_account_url } from "./endpoints";
import { login_url } from "./endpoints";
import { change_password_url } from "./endpoints";

export default function MainRouter() {
  return (
    <BrowserRouter>
      <NavBar />
      <MiniDrawer />
      <Routes>
        <Route path={home_url}>
          <Route index element={<Home />} />
          <Route path={login_url}>
            <Route index element={<LoginPage />} />
          </Route>
          <Route path={change_password_url} element={<ChangePassword />} />
          <Route path={forgot_password_url} element={<ForgotPassword />} />
          <Route path={create_account_url}>
            <Route index element={<CreateAccount />} />
            <Route path="confirmation" element={<StaffSubscriptionSetupConfirmation />} />
            <Route path="cancellation" element={<StaffSubscriptionSetupCancellation />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
