export interface StepInterface {
  label: string,
  description: string
}

export const payment_setup_steps: StepInterface[] = [
  {
    label: "Choose a Plan",
    description: "Choose any of the 4 available plans"
  },
  {
    label: "Add Billing Details",
    description: "Add the billing account details for the billing processor"
  },
  {
    label: "Add Payment",
    description: "Add payment details through the payment processor"
  }
];

export const staff_payment_setup_steps: StepInterface[] = [
  {
    label: "Choose Plan",
    description: "Choose any of the 4 available plans"
  },
  {
    label: "Create Account",
    description: "Setup the new customers account"
  },
  {
    label: "Billing Account",
    description: "Add the billing account details for the billing processor"
  },
  {
    label: "Add Payment",
    description: "Add payment details through the payment processor"
  }
]

export const is_account_setup_steps: StepInterface[] = [
  {
    label: "Billing Account Created",
    description: "No Stripe Customer Id is associated with the account"
  },
  {
    label: "Plan Chosen",
    description: "Add a subscription for the customer"
  },
  {
    label: "Payment Added",
    description: "Add payment through the Stripe portal"
  },
  {
    label: "Pending Account Confirmation",
    description: "Change pending account confirmation to 'Yes' if this is a dealer or a verified fabricator."
  },
  {
    label: "Subscription Active",
    description: "Move subscription active to 'Yes'"
  },
  {
    label: "Done",
    description: "The account is set up"
  }

]
