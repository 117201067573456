/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useEffect } from "react";
/// ACTIONS ///
import { setRegistrationComplete } from "api/actions/authentication";
/// MUI ///
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

interface Props {
  setStep: (step: number) => void
}

export default function CompleteRegistration({ setStep }: Props) {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setRegistrationComplete(false));
  }, [dispatch]);

  return (
    <Grid item justifyContent="start" alignItems="center">
      <Stack>
        <Typography variant="h6">
          Registration Complete
        </Typography>
        <Typography variant="body2">
          The customer has been registered. Ask them to check their email (including junk or spam folder) for the confirmation email. If you have any issues please contact the development team.
        </Typography>
        <Button onClick={() => setStep(0)}>
          Register Another
        </Button>
      </Stack>
    </Grid>
  );
}
