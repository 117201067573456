/// HOOKS ///
import useAddressPredictions from "hooks/geo/autocomplete";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
/// REACT COMPONENT ///
import { Fragment } from "react";
/// MUI COMPONENTS ///
import { TextField } from "@mui/material";
/// GEO COMPONENTS ///
import AutocompleteResults from "./results";

interface Results {
  results: any
}

interface Props {
  label: string,
  error: string,
  has_error: boolean,
  geoError: () => void,
  updateGeo: (label: string, lat: number, lng: number, address: string, city: string, province: string) => void,
  width?: number,
  input_label?: string
}
export default function AddressAutocomplete({ label, error, has_error, geoError, updateGeo, width = 540, input_label = "Address" }: Props) {
  const geo: any = useRef(null);
  const [res_open, setResOpen] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<HTMLDivElement | null>(null);
  const ref = useRef(null);

  if (geo && !geo.current) {
    geo.current = new window.google.maps.Geocoder();
  }

  useEffect(() => {
    if (ref?.current) {
      setAnchor(ref.current);
    }
  }, [ref]);

  const updateAddress = (address: string) => {
    updateGeo(address, 0, 0, "", "", "");
    setResOpen(true);
  }

  const choosePlace = (placeId: string, label: string, address: string, city: string, province: string) => {
    geo.current.geocode({ placeId })
      .then(({ results }: Results) => {
        updateGeo(
          label,
          Math.round(results[0].geometry.location.lat() * 1000000) / 1000000,
          Math.round(results[0].geometry.location.lng() * 1000000) / 1000000,
          address,
          city,
          province
        );
      })
      .catch(() => {
        geoError();
      });
  }

  const keyDown = (e: any) => {
    if (e.key === "Escape") {
      e.preventDefault();
      setResOpen(false);
      updateGeo("", 0, 0, "", "", "");
    }
  }

  const predictions = useAddressPredictions(label);

  return (
    <Fragment>
      <TextField
        sx={{ width: `${width}px` }}
        inputProps={{
          autocomplete: "new-password",
          form: {
            autocomplete: "off"
          }
        }}
        onKeyDown={keyDown}
        ref={ref}
        placeholder="Search Address"
        value={label}
        size="small"
        label={input_label}
        error={has_error}
        helperText={error}
        onChange={e => updateAddress(e.target.value)} />
      <AutocompleteResults
        anchorEl={anchor}
        open={res_open}
        setOpen={setResOpen}
        predictions={predictions}
        choosePlace={choosePlace} />
    </Fragment>
  )
}
