import { Action } from "api/action_types/sales";
import { ActionType } from "api/action_types/sales";
import { SalesData } from "api/types/sales";

interface State {
  sales_data: SalesData[]
}

const initialState = {
  sales_data: [],
}

export const salesReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case ActionType.GET_SALES_DATA:
      return {
        ...state,
        sales_data: action.sales
      }
    default:
      return state;
  }
}



