/// HOOKS ///
import { useAppDispatch } from "api/hooks/apiHook";
import { useAppSelector } from "api/hooks/apiHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
/// ACTIONS ///
import { getSalesData } from "api/actions/sales";
/// MUI ///
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// FM ///
import { motion } from "framer-motion";
/// CHART ///
import { LineChart } from "recharts";
import { Line } from "recharts";
/// COMPONENTS ///
import PageTitle from "components/universal/page/title";
/// STYLES ///
import { mainContentAreaStyle } from "styles/style";
/// ENDPOINTS ///
import { login_url } from "components/navigation/endpoints";
import { create_account_url } from "components/navigation/endpoints";
/// COLORS ///
import { light_grey_color } from "styles/style";
import { primary_color } from "styles/style";
/// MOTION ///
import { dash_btn_motion } from "motion/button";
import { dash_item_motion } from "motion/button";

export default function Home() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.authentication);
  const { sales_data } = useAppSelector((state) => state.sales);
  const [sales_change, setSalesChange] = useState<number>(0);
  const [sales_count, setSalesCount] = useState<number>(0);

  useEffect(() => {
    let count: number = 0;
    let current: number = 0;
    let last: number = 0;

    for (let i = 0; i < sales_data.length; i++) {
      count += sales_data[i].sales;

      if (i === 0) {
        current = sales_data[i].sales;
      }
      if (i === 1) {
        last = sales_data[i].sales;
      }
    }

    setSalesChange(current - last);
    setSalesCount(count);
  }, [sales_data]);

  useEffect(() => {
    dispatch(getSalesData());
  }, [dispatch]);

  if (!user.id) {
    navigate(login_url, { replace: false });
  }

  return (
    <Box sx={mainContentAreaStyle}>
      <Stack>
        <PageTitle title="Dashboard" />
        <Grid container sx={{ paddingTop: "20px" }}>
          <Grid item xs={12} md={6} lg={4} xl={3} onClick={() => navigate(create_account_url)}>
            <motion.button variants={dash_item_motion} whileHover="hover" initial="rest" style={{ width: "100%", padding: "20px", border: `1px solid ${light_grey_color}`, borderRadius: "2px", background: "none" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Stack>
                    <Typography variant="body1" align="left">
                      <b>Sales</b>
                    </Typography>
                    <Typography variant="body2" align="left" color="primary">
                      {sales_count} Total
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <LineChart width={150} height={100} data={sales_data}>
                      <Line type="monotone" dataKey="sales" stroke={primary_color} dot={false} />
                    </LineChart>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" align="left" color={sales_change > 0 ? "secondary" : "error"} sx={{ fontSize: 11, paddingTop: "55px" }}>
                    {sales_change} more than last month
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ paddingTop: "45px" }}>
                  <motion.div variants={dash_btn_motion} style={{ borderColor: primary_color, borderStyle: "solid", borderWidth: "0px" }}>
                    <Typography variant="body1" color="inherit">Create Customer +</Typography>
                  </motion.div>
                </Grid>
              </Grid>
            </motion.button>
          </Grid>
        </Grid>
      </Stack >
    </Box >
  )
}
