import { useEffect } from "react";
import { useState } from "react";
import { useAppDispatch } from "api/hooks/apiHook";
import { setProduct } from "api/actions/billing";
import { setBillingCustomerCreated } from "api/actions/billing";
import { setRegistrationComplete } from "api/actions/authentication";
/// MUI ///
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { RadioProps } from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
/// FM ///
import { motion } from "framer-motion";
/// MOTIONS ///
import { dash_item_motion } from "motion/button";
import { plan_inner_motion } from "motion/button";
/// MUI STYLES ///
import { styled } from "@mui/material/styles";
/// COLOR ///
import { light_grey_color } from "styles/style";
import { primary_color } from "styles/style";
/// PRODUCTS ///
import { SPONSORED_PRODUCT } from "settings";
import { SUBSCRIPTION_PRODUCT_ID } from "settings";
import { MONTHLY_PRICE_ID } from "settings";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark" ? "rgba(57,75,89,.5)" : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

interface Props {
  setStep: (step: number) => void
}

export default function StaffChoosePlan({ setStep }: Props) {
  const dispatch = useAppDispatch();
  const [is_dealer, setIsDealer] = useState<boolean>(true);

  useEffect(() => {
    setRegistrationComplete(false);
    setBillingCustomerCreated(false);
  }, []);

  const choosePlan = (product: string, price: string) => {
    dispatch(setProduct(product, price));
    setStep(1);
  }

  return (
    <Grid container item justifyContent="start" alignItems="center">
      <Grid item xs={12}>
        <RadioGroup row defaultValue="none" aria-labelledby="customer-radios" name="customer-radios" onChange={() => setIsDealer(prev => !prev)}>
          <FormControlLabel
            value={is_dealer}
            checked={is_dealer}
            control={<BpRadio />}
            label={
              <Typography variant="body2">
                Dealer
              </Typography>
            } />
          <FormControlLabel
            value={is_dealer}
            checked={!is_dealer}
            control={<BpRadio />}
            label={
              <Typography variant="body2">
                Fabricator
              </Typography>
            } />
        </RadioGroup>
      </Grid>
      {
        is_dealer ?
          <Grid item xs={12}>
            <motion.button
              onClick={() => choosePlan(SPONSORED_PRODUCT, "")}
              variants={dash_item_motion}
              whileHover="hover"
              initial="rest"
              style={{
                width: "100%",
                cursor: "pointer",
                height: "200px",
                padding: "20px",
                border: `1px solid ${light_grey_color}`,
                borderRadius: "2px",
                marginTop: "25px"
              }}>
              <motion.div initial="rest" variants={plan_inner_motion} style={{
                borderWidth: "0px",
                borderStyle: "solid",
                borderColor: primary_color,
                display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", height: "100%"
              }}>
                <Typography variant="h6" align="left">
                  <b>Sponsored Plan</b>
                </Typography>
                <Typography variant="body2" align="left">
                  0$ per month
                </Typography>
              </motion.div>
            </motion.button>
          </Grid> :
          <Grid item xs={12}>
            <motion.button
              onClick={() => choosePlan(SUBSCRIPTION_PRODUCT_ID, MONTHLY_PRICE_ID)}
              variants={dash_item_motion}
              whileHover="hover"
              initial="rest"
              style={{
                width: "100%",
                cursor: "pointer",
                height: "200px", padding: "20px", border: `1px solid ${light_grey_color}`, borderRadius: "2px", marginTop: "25px"
              }}>
              <motion.div initial="rest" variants={plan_inner_motion} style={{
                borderWidth: "0px",
                borderStyle: "solid",
                borderColor: primary_color,
                display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", height: "100%"
              }}>
                <Typography variant="h6" align="left">
                  <b>Monthly Subscription</b>
                </Typography>
                <Typography variant="body2" align="left">
                  199$ per month
                </Typography>
              </motion.div>
            </motion.button>
          </Grid>
      }
    </Grid>
  );
}
