/// AXIOS ///
import axios from "axios";
import { AxiosError } from "axios";
/// REDUX ///
import { Dispatch } from "redux";
import { RootState } from "api/reducers";
/// ACTIONS ///
import { Action } from "api/action_types/sales";
import { Action as AuthAction } from "api/action_types/authentication";
/// ACTION TYPES ///
import { ActionType } from "api/action_types/sales";
import { ActionType as AuthActionType } from "api/action_types/authentication";
/// URLS ///
import { url_prefix } from "settings";
/// TYPES ///
import { User } from "api/types/authentication";

const sales_url: string = "sales";
const sales_data_url: string = "sales_data";

export const getSalesData = () => {
  return async (dispatch: Dispatch<Action>, getState: () => RootState) => {
    const { user } = getState().authentication;

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${sales_data_url}`;

    try {
      const { data } = await axios.get(url, config);

      dispatch({
        type: ActionType.GET_SALES_DATA,
        sales: data
      });
    }
    catch (err) {
      if (err instanceof Error) {
        console.log(err);
      }
    }
  }
}

export const createSale = (sold_user: User) => {
  return async (dispatch: Dispatch<Action | AuthAction>, getState: () => RootState) => {
    const { user } = getState().authentication;
    dispatch({
      type: AuthActionType.REGISTER_PENDING
    });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Token ${user.token}`
      }
    }

    const url = `${url_prefix}${sales_url}/`;

    try {
      const { data } = await axios.post(url, sold_user, config);

      dispatch({
        type: ActionType.CREATE_SALE,
        sale: data
      });

      dispatch({
        type: AuthActionType.REGISTER_SUCCESS,
        payload: data
      });

    }
    catch (err) {
      if (err instanceof AxiosError && err.response && err.response.status === 400) {
        dispatch({
          type: AuthActionType.REGISTER_FAIL,
          payload: err?.response?.data?.error ?? "",
          offer_reset: true
        });
      }
      else {
        dispatch({
          type: AuthActionType.REGISTER_FAIL,
          payload: "An unknown error has occurred please contact the administrator",
          offer_reset: false
        });
      }
    }
  }
}
