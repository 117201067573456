import { Sale } from "api/types/sales";
import { SalesData } from "api/types/sales";

export enum ActionType {
  GET_SALE = "GET_SALE",
  GET_SALES_DATA = "GET_DATA_SALES",
  CREATE_SALE = "CREATE_SALE"
}

interface IGetSales {
  type: ActionType.GET_SALE,
  sale: Sale
}

interface IGetSalesData {
  type: ActionType.GET_SALES_DATA,
  sales: SalesData[]
}

interface ICreateSale {
  type: ActionType.CREATE_SALE,
  sale: Sale
}

export type Action = IGetSales | IGetSalesData | ICreateSale;
